import React from 'react';
import _ from 'lodash'
import { Box, Grid, Hidden, Typography } from "@material-ui/core";
import { connect } from "react-redux";
import SummaryBox from "components/organisms/SummaryBox/SummaryBox";
import ProgressBar from "components/molecules/ProgressBar/ProgressBar";
import { Helmet } from "react-helmet";
import ContactBox from "components/molecules/ContactBox/ContactBox";
import Paper from "components/atoms/Paper/Paper";
import member1 from "images/member-1-thumb.png";
import member2 from "images/member-2-thumb.png";
import member3 from "images/member-3-thumb.png";

const Summary = ({user, will }) => {

  return (
    <>
      <Helmet>
        <title>Summary</title>
        <meta name="segment-event-name" content="Summary | Viewed page" />
      </Helmet>
      <Grid container spacing={5}  justify="center">
        <Grid item md={6} >
          <Box mb={-4}>
            <Typography variant="h1">As-salamu alaikum, {user.preferred_name}!</Typography>
            <p className="font-secondary">

              {process.env.REACT_APP_PRESS_RELEASE ? <></> :
                <>
                  {!_.isEmpty(will) && will.status === null && "Let's sort out your Islamic will."}
                  {!_.isEmpty(will) && will.status === 'reviewing' && "We're checking your Islamic will."}
                  {!_.isEmpty(will) && will.status === 'approved' && !will.changed && "Your Islamic will has been approved."}
                  {!_.isEmpty(will) && will.status === 'approved' && will.changed && "Changes to your Islamic will have been approved."}
                  {!_.isEmpty(will) && will.status === 'change_requested' && "Let's sort out your Islamic will."}
                </>
              }
            </p>
            <Hidden smDown>

              {process.env.REACT_APP_PRESS_RELEASE ?
                <Paper>
                  <Grid container>
                    <Grid item xs={12}>
                      <Typography variant="subtitle1"><b>Good News, we have been acquired by Wahed!</b></Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography className="font-secondary">
                        <p>We are excited to announce that this online platform* has been acquired by Wahed and that they are currently improving this platform with ongoing updates. Therefore, for the time being you will be temporarily unable to create a new will until the updates are completed.</p>
                        <p>If you have already created a will with us, there is no need to worry as you can still login to see the Will.</p>
                        <p>To see if you qualify for a bespoke service, please click the link <a href="https://tally.so/r/3q5yjG">here</a></p>
                        <p>If you would like any more information on the acquisition, please read the press release <a href="https://www.wahed.com/blog/wahed-acquires-iwill-solicitors-to-help-muslims-prepare-for-the-future">here</a></p>
                        <p><br/><small>*Wahed Invest Limited acquired truewills.co.uk on 8th February 2023</small></p>

                      </Typography>

                    </Grid>
                  </Grid>
                </Paper> :
                <ContactBox/>
              }

            </Hidden>
          </Box>
        </Grid>
        {process.env.REACT_APP_PRESS_RELEASE ? <></> :
          <Grid item md={5}>
          {will &&
            <>
              <Box mb={0.5}>
                <Typography variant="p"><b>Your progress: { will.progress_percent }% complete</b></Typography>
              </Box>
              <ProgressBar variant="solid" value={will.progress_percent } />
            </>
          }
          <Box mt={4}>
            <SummaryBox />
            <Hidden mdUp>
              <Box mt={4}>
                <ContactBox />
              </Box>
            </Hidden>
          </Box>
        </Grid>
      }
      </Grid>
    </>
  )
              };

const mapStateToProps = ({ account, wills }) => ({
  user: account.user,
  will: wills.wills[0]
});


export default connect(
  mapStateToProps,
)(Summary);

